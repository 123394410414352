<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) How many moles of <chemical-latex content="Na3PO4" /> would there be in a sample with a
        mass of
        <number-value :value="mass" unit="\text{g?}" />
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) How many <b>atoms</b> of oxygen would be in that same sample?</p>

      <calculation-input
        v-model="inputs.atomsO"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{atoms}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) How many <b>atoms</b> of sodium would be in that same sample?</p>

      <calculation-input
        v-model="inputs.atomsNa"
        show-helpful-hint
        prepend-text="$\text{Amount:}$"
        append-text="$\text{atoms}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question383',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mol: null,
        atomsO: null,
        atomsNa: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
